import { ref, onMounted } from "vue";

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string }>;
}

export function useSavePwa() {
  const installPromptEvent = ref<BeforeInstallPromptEvent | null>(null);
  const isAppInstalled = ref(false);

  // Слушаем событие beforeinstallprompt
  onMounted(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      installPromptEvent.value = event as BeforeInstallPromptEvent;
    };

    const handleAppInstalled = () => {
      isAppInstalled.value = true;
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  });

  const showInstallPrompt = async () => {
    if (installPromptEvent.value) {
      const promptEvent = installPromptEvent.value;
      await promptEvent.prompt();
      const result = await promptEvent.userChoice;
      console.log("User choice:", result.outcome);
    }
  };

  return {
    isAppInstalled,
    showInstallPrompt,
  };
}
