<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { themeColorStore } from "@/store/themeColor";
import { storeToRefs } from "pinia";
// import NavSwitch from "@/components/index/NavSwitch.vue";
// import { LANGUAGES } from "@/package/variables/countriesAndLanguages";
import rcLogoFullLight from "@/assets/icons/rc-logo-full.svg";
import rcLogoFullDark from "@/assets/icons/rc-logo-full-dark.svg";
import { IconSunFill, IconSunThin } from "@packages/icon-library";
import { IS_PROD_MODE } from "@/boot/api";

const lang = ref("ru"); // Текущий язык
// const langOptions = LANGUAGES;
// const isOpenBackNotify = ref(true);

const themeStore = themeColorStore();
const { themeColor } = storeToRefs(themeStore);

const route = useRoute();

const { t } = useI18n();

onMounted(() => {
  themeStore.SET_THEME_COLOR_FROM_LOCAL_STORAGE();

  let localLang = localStorage.getItem("selected-language");
  if (!localLang) {
    localStorage.setItem("selected-language", "ru");
    localLang = "ru";
  } else if (localLang === "ru-ru") {
    // Если у вас ранее был сохранён "ru-ru"
    localLang = "ru";
    localStorage.setItem("selected-language", "ru");
  }
  lang.value = localLang;
});

function toggleTheme() {
  const newTheme = themeColor.value === "light" ? "dark" : "light";
  themeStore.SET_THEME_COLOR(newTheme);
}

function tokenClearing() {
  if (route.name === "Login") {
    return;
  }
  localStorage.removeItem("access_token");
  localStorage.removeItem("emailCode");
  localStorage.removeItem("email");
}

// function setLanguage(option: string) {
//   lang.value = option;
//   localStorage.setItem("selected-language", option);
//   // При желании перезагрузить страницу (или меняем $i18n.locale без reload)
//   window.location.reload();
// }

const logoSrc = computed(() => {
  return themeColor.value === "light" ? rcLogoFullDark : rcLogoFullLight;
});

const isInternalBackRoute = computed(() => {
  return route.meta?.is_internal_back_route ?? true;
});

const backButtonRoute = computed<{ name: string; text: string }>(() => {
  if (route.meta?.back_button_route) {
    return route.meta.back_button_route;
  }

  const specialRoutes = ["Login", "NewPassword", "NewManagerPassword"];
  if (specialRoutes.includes(route.name as string)) {
    return {
      text: t("navbar.page1"),
      name: "Lending",
    };
  }

  if (route.name === "ResetPassword") {
    return { text: "Назад", name: "Login" };
  }

  return { text: t("headerButtons.exit"), name: "Lending" };
});
</script>

<template>
  <section class="auth-layout">
    <div class="auth-layout__sidebar sidebar">
      <img class="sidebar__logo" :src="logoSrc" alt="logo" />

      <RouterView name="navigation" />
    </div>

    <div id="form-container" class="form-container">
      <div class="form-container__header">
        <template v-if="isInternalBackRoute">
          <RouterLink
            class="form-container__back-button"
            :to="{ name: backButtonRoute.name }"
            @click="tokenClearing"
          >
            <span class="icon-back-arrow" />
            <p>{{ backButtonRoute.text }}</p>
          </RouterLink>
        </template>
        <template v-else>
          <a
            class="form-container__back-button"
            :href="backButtonRoute.name"
            @click="tokenClearing"
          >
            <span class="icon-back-arrow" />
            <p>{{ backButtonRoute.text }}</p>
          </a>
        </template>

        <Transition name="theme-switch" mode="out-in">
          <component
            :is="themeColor === 'light' ? IconSunFill : IconSunThin"
            v-if="!IS_PROD_MODE"
            :key="themeColor"
            class="theme-icon"
            @click="toggleTheme"
          />
        </Transition>
      </div>

      <div class="form-container__page">
        <RouterView />
      </div>

      <!--
      <div class="form-container__footer">
        <NavSwitch
          v-model="lang"
          :options="langOptions"
          list-position="top-left"
          without-mobile-style
          @select="setLanguage"
        />
      </div>
      -->
    </div>
  </section>
</template>

<style lang="scss" scoped>
.auth-layout {
  display: grid;
  grid-template-columns: 320px 1fr;
  height: 100vh;
}

.sidebar {
  background: var(--color-semantic-background-normal-bg-secondary);
  padding: 0 40px;

  &__logo {
    width: 112px;
    height: 40px;
    margin: 16px 0;
  }
}

.form-container {
  height: 100%;
  display: grid;
  grid-template-rows: 72px 1fr 48px;
  overflow: auto;

  &__header {
    height: fit-content;
    padding: 40px 40px 0 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      padding: 36px 24px;
    }

    @media (max-width: 411px) {
      padding: 36px 16px;
    }
  }

  &__back-button {
    color: var(--color-semantic-content-normal-link);
    padding: 0;
    display: flex;
    gap: 4px;
    text-decoration: none;
    cursor: pointer;

    p {
      cursor: pointer;
    }

    .icon-back-arrow {
      width: 24px;
      height: 24px;
      background: var(--color-semantic-content-normal-link);
    }
  }

  &__page {
    @media (max-width: 1365px) {
      display: flex;
      align-items: center;
    }
    @media (max-width: 767px) {
      display: block;
    }
  }

  &__footer {
    padding: 0 40px;
    display: flex;
    align-items: flex-start;
  }
}

@media (max-width: 1279px) {
  .auth-layout {
    grid-template-columns: 1fr;
  }

  .sidebar {
    display: none;
  }
}

.theme-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: var(--color-semantic-content-normal-primary);
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--color-semantic-content-hover-primary);
  }
}

.theme-switch-enter-active,
.theme-switch-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
  display: inline-block;
}
.theme-switch-enter,
.theme-switch-leave-to {
  opacity: 0;
  transform: rotate(0deg);
}
.theme-switch-enter-to,
.theme-switch-leave {
  opacity: 1;
  transform: rotate(360deg);
}
</style>
